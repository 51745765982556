var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-container", [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.markdown,
          expression: "markdown",
        },
      ],
      staticClass: "input-window",
      domProps: { value: _vm.markdown },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.markdown = $event.target.value
        },
      },
    }),
    _c("div", { domProps: { innerHTML: _vm._s(_vm.markdownStringToHtml) } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }