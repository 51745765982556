<template>
  <b-container>
    <textarea
      v-model="markdown"
      class="input-window"
    />
    <div v-html="markdownStringToHtml" />
  </b-container>
</template>

<script>
import { markdownMixin } from '@/mixins/markdownMixin.js'

export default {
  name: 'PublicApiDocsAdminTools',
  mixins: [markdownMixin],
  data() {
    return {
      markdown: '# Markdown Editor \n' +
        '## Enter documentation to test valid markdown against styling\n',
      }
  },
  computed: {
    markdownStringToHtml() {
      return this.formatMarkdownToHtml(this.markdown)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../api-management-shared-styles';

.input-window {
  width: 100%;
  min-height: 10em;
  margin-top: 2em;
}
</style>
